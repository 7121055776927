var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"6","lg":"10","md":"8","sm":"6"}},[_c('h3',[_vm._v("Configuração do Distribuidor")])]),_c('v-col',{staticStyle:{"text-align":"right"},attrs:{"cols":"2"}},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"to":{ name: 'distribuidorConfigForm', params: {id: 0} }}},'v-btn',attrs,false),on),[_vm._v(" Nova Configuração ")])]}}])},[_c('span',[_vm._v("Nova Configuração")])])],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.getRegisters($event)}}},[_c('v-text-field',{attrs:{"label":"Pesquisa Rápida","append-outer-icon":"mdi mdi-magnify","single-line":"","outlined":""},on:{"click":function($event){_vm.options.page=1;},"click:append-outer":_vm.getRegisters},model:{value:(_vm.filter.fastSearch),callback:function ($$v) {_vm.$set(_vm.filter, "fastSearch", $$v)},expression:"filter.fastSearch"}})],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.filteredSearch,"options":_vm.options,"loading":_vm.loading,"items-per-page":10,"no-data-text":"Nenhum registro encontrado","footer-props":{
                        'items-per-page-text':'Registros por Página',
                        'items-per-page-all-text':'Todos',
                        pageText: '{0}-{1} de {2}'
                    }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
                    var item = ref.item;
return [_c('ActionList',{attrs:{"id":item.id,"link":'distribuidorConfigForm',"showButtons":{
                                edit: true,
                                delete: false,
                                visualizarHistorico: true,
                            }},on:{"confirmDelete":_vm.confirmDelete,"visualizarHistorico":_vm.visualizarHistorico}})]}}])})],1)],1),_c('v-dialog',{attrs:{"transition":"dialog-top-transition","persistent":"","width":"90%","fullscreen":_vm.$vuetify.breakpoint.mobile},model:{value:(_vm.showHistory),callback:function ($$v) {_vm.showHistory=$$v},expression:"showHistory"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 lighten-2",staticStyle:{"border-bottom":"solid 1px var(--color__cinza)"}},[_vm._v(" Histórico de Alterações ")]),_c('v-card-text',{staticStyle:{"margin-top":"20px","text-align":"center"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headerHistorico,"items":_vm.listHistorico,"single-expand":true,"expanded":_vm.expanded,"item-key":"id","show-expand":"","no-data-text":"Não há histórico"},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"expanded-item",fn:function(ref){
                            var headers = ref.headers;
                            var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-simple-table',{attrs:{"height":"300px"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v(" Marca ")]),_c('th',{staticClass:"text-left"},[_vm._v(" Desconto ")])])]),_c('tbody',_vm._l((item.listDesconto),function(itemDesconto){return _c('tr',{key:itemDesconto.id},[_c('td',{staticClass:"text-left"},[_vm._v(_vm._s(itemDesconto.marcaSelected.descricao))]),_c('td',{staticClass:"text-left"},[_vm._v(_vm._s(itemDesconto.descontoFormatted)+" %")])])}),0)]},proxy:true}],null,true)})],1)]}}])})],1),_c('v-divider'),_c('v-card-actions',[_c('div',{staticStyle:{"width":"100%","text-align":"center"}},[_c('v-btn',{staticClass:"mx-2",attrs:{"outlined":""},on:{"click":function($event){_vm.showHistory = false}}},[_vm._v(" OK ")])],1)])],1)],1),_c('ActionDialog',{attrs:{"showDialog":_vm.dialog.show,"headerTitle":_vm.dialog.headerText,"bodyText":_vm.dialog.bodyText,"params":_vm.dialog.params},on:{"methodConfirmToCall":_vm.dialog.methodConfirm,"update:showDialog":function($event){return _vm.$set(_vm.dialog, "show", $event)},"update:show-dialog":function($event){return _vm.$set(_vm.dialog, "show", $event)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }